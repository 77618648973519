/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 7/24/20
 * Time: 3:23 PM
 */
import React from 'react';
import * as actionTypes from './actionType';

import instituteLogo from '../../../assets/img/logo/Passsme.lk.png';
import defaultImage from '../../../assets/img/logo/Passsme.lk.png';

let about =
    <div>
        <p className={"none-margin_ text-align-justify"}>
            Sri Lanka's Latest Educational Mobile Network
        </p>
    </div>;
let address =
    <div>
        <h5>

            No.520, Ethgala, Gampola, Kandy

        </h5>
        <h6
            style={{fontSize: 14}}>
        </h6>

    </div>;

    let mobile = <h5>0713477770</h5>;



const initialState = {
    institute: {
        name: "PassMe Institute",
        about: about,
        image: instituteLogo,
        defaultImage: defaultImage,
        address: address,
        mobile:mobile,
    },
    teachers: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.INSTITUTE_HANDLER:
            return {
                ...state,
                institute: action.value
            };
        case actionTypes.FETCH_TEACHERS:
            return {
                ...state,
                teachers: action.value
            };
        default:
            return state;
    }
};

export default reducer;
